.logo {
  svg {
    display: block;
    width: rem(90);
    height: auto;
  }
  svg path {
    transition: fill .3s ease-in-out;
    @include c-primary('fill');
  }

  @include when-is(a) {
    &:after {
      display: none;
    }
  }
}

.logo.logo--white {
  svg path { @include c-negative('fill'); }
}


@include above($tablet) {
  .logo {
    svg {
      width: rem(140);
      height: auto;
    }
  }
}
