
.case-teaser--tutushop {
  @include c-negative('background-color');
  @include c-primary('color');

  .case-teaser__visual {
    width: rem(154);
    height: rem(256);
    left: rem(142);
    top: rem(157);
  }
}

@include above($tablet) {
  .case-teaser--tutushop {
    .case-teaser__title {
      font-size: 11.805vw;
      letter-spacing: ls(-8.11, 170);
      font-weight: 400;

      span:last-child {
        position: relative;
        left: 9.72vw;
      }
    }

    .case-teaser__visual {
      width: 20.763vw;
      height: 52.013vw;
      top: 4.31vw;
      left: 44.1vw;
    }
  }
}

@include above($desktop-hd) {
  .case-teaser--tutushop {
    .case-teaser__title {
      font-size: rem(170);

      span:last-child {
        left: rem(140);
      }
    }

    .case-teaser__visual {
      top: rem(62);
      left: rem(635);
      width: rem(299);
      height: rem(749);
    }
  }
}
