.service-block {
  text-align: center;
  margin-bottom: rem(20);

  &__visual {
    svg {
      width: rem(30);
      height: auto;

      path { fill: #D2D2D7; }
    }
  }

  &__title {
    @include c-primary('color');

    font-weight: 400;
    letter-spacing: ls(5.5,22);
    line-height: lh(26,22);
    text-transform: uppercase;
    margin: rem(20) 0;
  }

  &__description {
    margin: 0;
    font-size: rem(16);
    letter-spacing: ls(1,16);
    line-height: lh(24,16);
  }
}
