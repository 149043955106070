.get-in-touch {
  padding: rem(70) 0 rem(40);

  &__inner {
    display: flex;
    flex-direction: column;
    align-items: center;
  }


  .subscribe-form {
    max-width: rem(547);
    width: 100%;
    margin-top: rem(50);
  }
}


@include above($tablet) {
  .get-in-touch {
    padding: rem(100) 0;
  }
}
