.site-header {
  &__inner {
    height: rem(86);
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}



.site-header--blue {
  @include c-primary('background-color');

  .nav-primary { color: $white; }

  .logo svg path { fill: $white; }
}


.show-menu .site-header--blue {
  .logo { z-index: 100; }
  .logo svg path { @include c-primary('fill'); }
}


@include above($tablet) {
  .site-header {
    &__inner {
      height: rem(120);
    }
  }
}
