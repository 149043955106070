@mixin c-primary($props...) {
  @each $prop in $props {
    #{$prop}: $primary-color;
  }
}


@mixin c-secondary($props...) {
  @each $prop in $props {
    #{$prop}: $secondary-color;
  }
}


@mixin c-negative($props...) {
  @each $prop in $props {
    #{$prop}: $white;
  }
}

@mixin c-copy($props...) {
  @each $prop in $props {
    #{$prop}: $text-color;
  }
}


.copy {
  &-bg      { @include c-copy('background-color') }
  &-fill    { svg path, svg polygon { @include c-copy('fill') } }
  &-text    { @include c-copy('color') }
  &-border  { @include c-copy('border-color') }
}

.primary {
  &-bg      { @include c-primary('background-color') }
  &-fill    { svg path, svg polygon { @include c-primary('fill') } }
  &-text    { @include c-primary('color') }
  &-border  { @include c-primary('border-color') }
}

.secondary {
  &-bg      { @include c-secondary('background-color') }
  &-fill    { svg path, svg polygon { @include c-secondary('fill') } }
  &-text    { @include c-secondary('color') }
  &-border  { @include c-secondary('border-color') }
}

.negative {
  &-bg      { @include c-negative('background-color') }
  &-fill    { svg path, svg polygon { @include c-negative('fill') } }
  &-text    { @include c-negative('color') }
  &-border  { @include c-negative('border-color') }
}
