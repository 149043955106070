
.case-teaser--martens {
  @include c-secondary('background-color');
  @include c-negative('color');

  .case-teaser__title {
    max-width: rem(300);
  }

  .case-teaser__description {
    color: inherit;
  }

  .case-teaser__visual {
    width: rem(164);
    height: rem(245);
    left: rem(180);
    top: rem(135);
  }
}


@include above($tablet) {
  .case-teaser--martens {
    .case-teaser__title {
      font-size: 6.944vw;
      display: flex;
      font-weight: 400;
      max-width: 100%;

      span:last-child {
        position: relative;
        left: 19.79vw;
      }
    }

    .case-teaser__visual {
      width: 25.624vw;
      height: 38.680vw;
      left: 31.46vw;
      top: 12.85vw;
    }

    .case-teaser__description {
      max-width: 25.14vw;
    }
  }
}


@include above($desktop-hd) {
  .case-teaser--martens {
    .case-teaser__title {
      font-size: rem(100);

      span:last-child {
        left: rem(285);
      }
    }

    .case-teaser__visual {
      top: rem(185);
      left: rem(453);
      width: rem(369);
      height: rem(557);
    }

    .case-teaser__description {
      max-width: rem(362);
    }
  }
}
