.progressbar {
  width: 100%;
  position: relative;
  height: rem(5);
  z-index: 0;

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: rem(2);
    width: 100%;
    height: rem(1);
    background-color: #979797;
    z-index: 0;
  }

  &__progress {
    @include c-primary('background-color');

    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}
