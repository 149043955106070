.p-org {
  @include c-primary('color');
  margin: 0;
  font-size: rem(14);
  letter-spacing: ls(1.5, 14);
  text-transform: uppercase;
  margin-bottom: rem(10);
}

.h-adr {
  margin-bottom: rem(20);
}

.p-tel, .u-email {
  margin-bottom: rem(8);
}

@include above($tablet) {
  .h-card {
    display: flex;
    flex-wrap: wrap;
  }

  .p-org {
    width: rem(140);
  }

  .h-adr {
    width: rem(170);

  }

  .p-org, .h-adr, .p-tel, .u-email {
    margin-bottom: 0;
  }

  .h-adr, .p-tel, .u-email {
    margin-right: rem(40);
  }
}
