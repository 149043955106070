.hero {
  background-color: $blue;
  color: $white;
  height: calc(100vh - 86px);
  position: relative;
  overflow: hidden;

  &__inner {
    height: 100%;
    display: flex;
    align-items: center;
  }

  &__title {
    margin: 0;
    font-size: 12.5vw;
    letter-spacing: ls(-0.84, 40);
    line-height: 1;
    font-weight: 400;
    position: absolute;

    &__part {
      display: block;
      position: relative;
      z-index: 1;
    }
  }

  &__visual {
    width: 60vw;
    height: 140vw;
    background-image: url('../img/hero/hand.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: top right;
    position: absolute;
    right: rem(10);
    z-index: 0;
  }

  &__social {
    font-size: rem(12);
    letter-spacing: ls(-0.25, 12);
    color: $white;
    position: absolute;
    transform: rotate(90deg);
    transform-origin: top right;
    bottom: rem(50);
    right: rem(25);
    z-index: 100;

    a {
      display: inline-block;
      color: $white;
      text-decoration: none;
      transition: color .3s ease-in-out;
    }

    a + a {
      margin-left: 2em;
    }
  }

  &__text {
    display: none;
  }

  .btn-down {
    position: absolute;
    left: rem(25);
    bottom: rem(50);
    // margin-left: rem(-20);
    z-index: 3;
  }
}

.show-menu {
  .hero__social a {
    color: $primary-color;
  }
}

@include above($tablet) {
  .hero {
    height: 54.166vw;

    &__inner {
      display: block;
    }

    &__title {
      font-size: 6.666vw;
      letter-spacing: ls(-2, 96);
      top: 0;
      left: 0;
      right: 0;

      &__part {
        position: absolute;

        &:nth-child(1),
        &:nth-child(2) {
          top: 13.888vw;
        }

        &:nth-child(1),
        &:nth-child(3){
          right: 50%;
        }

        &:nth-child(2) {
          left: calc(50% + 5.555vw);
        }

        &:nth-child(4) {
          left: calc(50% + 8.68vw);
        }

        &:nth-child(3),
        &:nth-child(4){
          top: 21.527vw;
        }

        &:nth-child(1) {
          z-index: 2;
        }

        &:nth-child(2),
        &:nth-child(4) {
          z-index: 0;
        }

        &:nth-child(3) {

          z-index: 2;
        }
      }
    }

    &__visual-part,
    &__visual {
      width: 27.777vw;
      height: 65.822vw;
      top: 0;
      right: auto;
      left: calc(50% - 2.777vw);
      transform: translateX(-50%);
      z-index: 1;
    }

    &__visual-part {
      position: absolute;
      z-index: 3;
      background-image: url('../img/hero/hand-finger-layer.png');
      background-size: contain;
      background-repeat: no-repeat;
    }

    &__text {
      display: block;
      position: absolute;
      top: auto;
      bottom: 18.055vw;
      left: 0;
      right: 0;
      text-align: center;
      z-index: 3;
      padding: 0 rem(30);
    }

    .btn-down {
      position: absolute;
      left: 50%;
      margin-left: rem(-20);
      z-index: 3;
      bottom: 4.166vw;
    }
  }
}

@include above($desktop-hd) {
  .hero {
    height: rem(780);

    &__title {
      font-size: rem(96);
      letter-spacing: ls(-2, 96);

      &__part {
        &:nth-child(1),
        &:nth-child(2) {
          top: rem(200);
        }

        &:nth-child(2) {
          left: calc(50% + #{rem(80)});
        }

        &:nth-child(4) {
          left: calc(50% + #{rem(125)});
        }

        &:nth-child(3),
        &:nth-child(4) {
          top: rem(310);
        }
      }
    }

    &__visual-part,
    &__visual {
      left: calc(50% - #{rem(40)});
      width: rem(400);
      height: rem(947.89);
    }

    &__text {
      bottom: rem(260);
    }

    .btn-down {
      bottom: rem(60);
    }
  }
}
