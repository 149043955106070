.case-tags {
  display: flex;
  flex-wrap: wrap;

  .tag {
    margin-right: 1.2em;

    &:last-child {
      margin-right: 0;
    }
  }
}
