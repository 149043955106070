p {
  font-weight: 300;
  font-size: rem(20);
  letter-spacing: ls(1,20);
  line-height: lh(32,20);
}

.columned {
  column-count: 2;
  column-gap: rem(40);
}

p.smaller {
  font-size: rem(16);
  letter-spacing: ls(1,16);
  line-height: lh(24,16);
}
