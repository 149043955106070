.nav-secondary {
  margin-bottom: rem(30);
  font-weight: 400;

  &__list {
    display: flex;
    align-items: center;
  }

  &__item + &__item {
    margin-left: rem(40);
  }
}


@include above($tablet) {
  .nav-secondary {
    margin-bottom: 0;

    &__item:first-child {
      width: rem(140);
    }

    &__item + &__item {
      margin-left: 0;
    }
  }
}
