// ==========================================================================
// Layout
// Layout rules divide the page into sections. Layouts hold one or more
// modules together.
// ==========================================================================

.constrict {
  max-width: rem($desktop-md);
  margin: auto;
  padding: 0 rem(25);

  @include above($tablet) {
    padding: 0 rem(30);
  }
}
