$headers: ("h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6");

#{$headers} {
  // text-transform: uppercase;
  // margin: 1em 0;
}

h1, .h1 {
  font-size: rem(30);
  margin: .67em 0;
  letter-spacing: ls(1, 30);
}

h2, .h2 {
  font-size: rem(26);
  margin: .83em 0;
  letter-spacing: ls(1, 26);
}

h3, .h3 {
  font-size: rem(22);
  margin: 1em 0;
  letter-spacing: ls(1, 22);
}

h4, .h4 {
  font-size: rem(18);
  margin: 1.33em 0;
  letter-spacing: ls(1, 18);
}

h5, .h5, h6, .h6 {
  font-size: rem(16);
  margin: 1.67em 0;
  letter-spacing: ls(1, 16);
}

.article {
  h3, .h3 {
    font-size: rem(25);
    line-height: lh(40,25);
    letter-spacing: ls(1, 25);
  }
}
