@charset "UTF-8";
@media screen and (max-width: 1440px) {
  .foo {
    bar: 'below'; } }

@media screen and (max-width: 1439px) {
  .foo {
    bar: 'below -1'; } }

@media screen and (max-width: 1250px) {
  .foo {
    bar: 'below'; } }

@media screen and (max-width: 1249px) {
  .foo {
    bar: 'below -1'; } }

@media screen and (max-width: 1100px) {
  .foo {
    bar: 'below'; } }

@media screen and (max-width: 1099px) {
  .foo {
    bar: 'below -1'; } }

@media screen and (max-width: 900px) {
  .foo {
    bar: 'below'; } }

@media screen and (max-width: 899px) {
  .foo {
    bar: 'below -1'; } }

@media screen and (max-width: 768px) {
  .foo {
    bar: 'below'; } }

@media screen and (max-width: 767px) {
  .foo {
    bar: 'below -1'; } }

@media screen and (max-width: 600px) {
  .foo {
    bar: 'below'; } }

@media screen and (max-width: 599px) {
  .foo {
    bar: 'below -1'; } }

@media screen and (max-width: 480px) {
  .foo {
    bar: 'below'; } }

@media screen and (max-width: 479px) {
  .foo {
    bar: 'below -1'; } }

@media screen and (min-width: 479px) {
  .foo {
    bar: 'above -1'; } }

@media screen and (min-width: 480px) {
  .foo {
    bar: 'above'; } }

@media screen and (min-width: 599px) {
  .foo {
    bar: 'above -1'; } }

@media screen and (min-width: 600px) {
  .foo {
    bar: 'above'; } }

@media screen and (min-width: 767px) {
  .foo {
    bar: 'above -1'; } }

@media screen and (min-width: 768px) {
  .foo {
    bar: 'above'; } }

@media screen and (min-width: 899px) {
  .foo {
    bar: 'above -1'; } }

@media screen and (min-width: 900px) {
  .foo {
    bar: 'above'; } }

@media screen and (min-width: 1099px) {
  .foo {
    bar: 'above -1'; } }

@media screen and (min-width: 1100px) {
  .foo {
    bar: 'above'; } }

@media screen and (min-width: 1249px) {
  .foo {
    bar: 'above -1'; } }

@media screen and (min-width: 1250px) {
  .foo {
    bar: 'above'; } }

@media screen and (min-width: 1439px) {
  .foo {
    bar: 'above -1'; } }

@media screen and (min-width: 1440px) {
  .foo {
    bar: 'above'; } }

html {
  box-sizing: border-box;
  line-height: 1.15; }

*,
*::before,
*::after {
  box-sizing: inherit; }

:root {
  -moz-tab-size: 4;
  tab-size: 4; }

body {
  margin: 0;
  font-family: "europa", sans-serif;
  color: #323241;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

hr {
  height: 0; }

abbr[title] {
  text-decoration: underline dotted; }

b,
strong {
  font-weight: 700; }

code,
kbd,
samp,
pre {
  font-family: SFMono-Regular, Consolas, 'Liberation Mono', Menlo, Courier, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

small {
  font-size: 80%; }

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0; }

button,
select {
  text-transform: none; }

button,
[type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  border-style: none;
  padding: 0; }

button:-moz-focusring,
[type='button']:-moz-focusring,
[type='reset']:-moz-focusring,
[type='submit']:-moz-focusring {
  outline: 1px dotted ButtonText; }

fieldset {
  padding: 0.35em 0.75em 0.625em; }

legend {
  padding: 0; }

progress {
  vertical-align: baseline; }

[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto; }

[type='search'] {
  -webkit-appearance: textfield;
  outline-offset: -2px; }

[type='search']::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */ }

summary {
  display: list-item; }

.copy-bg {
  background-color: #323241; }

.copy-fill svg path, .copy-fill svg polygon {
  fill: #323241; }

.copy-text {
  color: #323241; }

.copy-border {
  border-color: #323241; }

.primary-bg {
  background-color: #0433FF; }

.primary-fill svg path, .primary-fill svg polygon {
  fill: #0433FF; }

.primary-text {
  color: #0433FF; }

.primary-border {
  border-color: #0433FF; }

.secondary-bg {
  background-color: #001446; }

.secondary-fill svg path, .secondary-fill svg polygon {
  fill: #001446; }

.secondary-text {
  color: #001446; }

.secondary-border {
  border-color: #001446; }

.negative-bg {
  background-color: #fff; }

.negative-fill svg path, .negative-fill svg polygon {
  fill: #fff; }

.negative-text {
  color: #fff; }

.negative-border {
  border-color: #fff; }

blockquote {
  color: #0433FF;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 6.25rem 0; }
  blockquote::before {
    content: '“';
    font-size: 6.25rem;
    font-weight: 300;
    font-style: italic;
    line-height: 0.37;
    margin-bottom: 1.25rem; }
  blockquote p, blockquote cite {
    font-size: 1.125rem;
    line-height: 2.05556;
    letter-spacing: 0.08333em;
    max-width: 42.8125rem; }
  blockquote p {
    font-weight: 700;
    text-transform: uppercase;
    margin: 0; }
  blockquote cite {
    font-weight: 300;
    font-style: normal;
    margin-top: 1.25rem;
    text-decoration: underline; }

button {
  padding: 0;
  appearance: none;
  border: none;
  background: transparent;
  cursor: pointer; }

.btn {
  color: inherit;
  display: inline-flex;
  height: 3.125rem;
  align-items: center;
  justify-content: center;
  border-radius: 0.125rem;
  font-size: 0.875rem;
  padding: 0.57143em 1em;
  text-transform: uppercase;
  font-weight: 700; }
  a.btn {
    text-decoration: none; }
    a.btn::after {
      display: none; }
  @media screen and (min-width: 768px) {
    .btn {
      height: 2.5rem; } }

.btn--ghost {
  color: #0433FF;
  border: 1px solid;
  background-color: transparent; }
  .btn--ghost.btn--white {
    color: #fff; }

.btn--primary {
  border-color: #0433FF;
  background-color: #0433FF;
  color: #fff;
  border-width: 1px;
  border-style: solid; }
  .btn--primary.btn--white {
    border-color: #fff;
    background-color: #fff;
    color: #0433FF; }

.btn--large {
  height: 3.125rem;
  font-size: 1rem;
  max-width: 17.25rem;
  width: 100%; }

.btn-down {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  background-color: #0433FF;
  color: #fff;
  border: 2px solid;
  display: inline-flex;
  justify-content: center;
  align-items: center; }
  .btn-down svg {
    margin: 0.1875rem 0 0 0;
    display: block;
    width: 1.625rem;
    height: auto; }
    .btn-down svg path, .btn-down svg polygon {
      fill: #fff; }

.btn-text {
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  font-size: 0.875rem;
  text-transform: uppercase;
  font-weight: 700;
  color: inherit;
  position: relative; }
  .btn-text::after {
    width: auto;
    left: 0;
    right: 1.5em; }
  .btn-text .icn {
    margin: 0 0 0 .2em; }
  .btn-text svg {
    display: block; }
    .btn-text svg path, .btn-text svg polygon {
      fill: currentColor; }
  .btn-text--white {
    color: #fff; }

.detail-list {
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 0.875rem;
  font-weight: 300;
  letter-spacing: 0.10714em;
  line-height: 1;
  color: #5F5F6E; }
  .detail-list li {
    display: flex;
    margin-bottom: 1.375rem; }
    .detail-list li span:first-child {
      width: 10em; }
    .detail-list li span {
      border-bottom: 1px solid; }
    .detail-list li a {
      color: inherit;
      text-decoration: none; }

.text-input {
  font-size: 0.875rem;
  letter-spacing: 0.125em;
  width: 100%;
  border: none;
  border-bottom: 1px solid #D2D2D7;
  padding-top: 0.9375rem;
  padding-bottom: 0.5rem; }
  .text-input::-webkit-input-placeholder {
    color: rgba(95, 95, 110, 0.5); }
  .text-input:-moz-placeholder {
    color: rgba(95, 95, 110, 0.5); }
  .text-input::-moz-placeholder {
    color: rgba(95, 95, 110, 0.5); }
  .text-input:-ms-input-placeholder {
    color: rgba(95, 95, 110, 0.5); }

a {
  position: relative;
  display: inline-block; }
  a::after {
    content: '';
    position: absolute;
    bottom: 0;
    display: block;
    width: 100%;
    height: 1px;
    background-color: currentColor;
    transition: transform .3s ease-in-out;
    transform-origin: right top;
    transform: scale(0, 1); }
  a:hover::after {
    transform-origin: left top;
    transform: scale(1, 1); }

.logo svg {
  display: block;
  width: 5.625rem;
  height: auto; }

.logo svg path {
  transition: fill .3s ease-in-out;
  fill: #0433FF; }

a.logo:after {
  display: none; }

.logo.logo--white svg path {
  fill: #fff; }

@media screen and (min-width: 768px) {
  .logo svg {
    width: 8.75rem;
    height: auto; } }

.mobile-nav-trigger {
  position: absolute;
  z-index: 100;
  right: 1.25rem;
  font-size: 2.5rem;
  color: #fff;
  height: 2.5rem;
  width: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none; }
  .mobile-nav-trigger::before, .mobile-nav-trigger::after {
    content: '';
    display: block;
    width: 1.25rem;
    height: 0.09375rem;
    position: absolute;
    background-color: #fff;
    transform: rotate(0);
    transition-property: background-color, margin-top, transform;
    transition-duration: .3s;
    transition-timing-function: ease-in-out;
    transition-delay: 0s, .3s, 0s; }
  .mobile-nav-trigger::before {
    margin-top: -0.1875rem; }
  .mobile-nav-trigger::after {
    margin-top: 0.1875rem; }
  .show-menu .mobile-nav-trigger::before, .show-menu .mobile-nav-trigger::after {
    background-color: #0433FF;
    transition-delay: 0s, 0s, .3s; }
  .show-menu .mobile-nav-trigger::before {
    margin-top: 0;
    transform: rotate(-45deg); }
  .show-menu .mobile-nav-trigger::after {
    margin-top: 0;
    transform: rotate(45deg); }

@media screen and (min-width: 768px) {
  .mobile-nav-trigger {
    display: none; } }

nav ol,
nav ul {
  list-style: none;
  padding: 0;
  margin: 0; }

nav a {
  text-decoration: none;
  color: inherit; }

p {
  font-weight: 300;
  font-size: 1.25rem;
  letter-spacing: 0.05em;
  line-height: 1.6; }

.columned {
  column-count: 2;
  column-gap: 2.5rem; }

p.smaller {
  font-size: 1rem;
  letter-spacing: 0.0625em;
  line-height: 1.5; }

.progressbar {
  width: 100%;
  position: relative;
  height: 0.3125rem;
  z-index: 0; }
  .progressbar::before {
    content: '';
    display: block;
    position: absolute;
    top: 0.125rem;
    width: 100%;
    height: 0.0625rem;
    background-color: #979797;
    z-index: 0; }
  .progressbar__progress {
    background-color: #0433FF;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0; }

.tag {
  font-size: 0.75rem;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 0.16667em; }
  @media screen and (min-width: 768px) {
    .tag {
      font-size: 0.875rem;
      letter-spacing: 0.09357em; } }

h1, .h1 {
  font-size: 1.875rem;
  margin: .67em 0;
  letter-spacing: 0.03333em; }

h2, .h2 {
  font-size: 1.625rem;
  margin: .83em 0;
  letter-spacing: 0.03846em; }

h3, .h3 {
  font-size: 1.375rem;
  margin: 1em 0;
  letter-spacing: 0.04545em; }

h4, .h4 {
  font-size: 1.125rem;
  margin: 1.33em 0;
  letter-spacing: 0.05556em; }

h5, .h5, h6, .h6 {
  font-size: 1rem;
  margin: 1.67em 0;
  letter-spacing: 0.0625em; }

.article h3, .article .h3 {
  font-size: 1.5625rem;
  line-height: 1.6;
  letter-spacing: 0.04em; }

.case-tags {
  display: flex;
  flex-wrap: wrap; }
  .case-tags .tag {
    margin-right: 1.2em; }
    .case-tags .tag:last-child {
      margin-right: 0; }

.subscribe-form .text-input {
  text-align: center; }

.subscribe-form .btn {
  max-width: 100%;
  width: 100%;
  margin-top: 1.25rem; }

@media screen and (min-width: 768px) {
  .subscribe-form {
    display: flex;
    justify-content: space-between; }
    .subscribe-form .text-input {
      width: calc(100% - 7.5rem);
      text-align: left; }
    .subscribe-form .btn {
      margin: 0;
      width: 6.5625rem; } }

.p-org {
  color: #0433FF;
  margin: 0;
  font-size: 0.875rem;
  letter-spacing: 0.10714em;
  text-transform: uppercase;
  margin-bottom: 0.625rem; }

.h-adr {
  margin-bottom: 1.25rem; }

.p-tel, .u-email {
  margin-bottom: 0.5rem; }

@media screen and (min-width: 768px) {
  .h-card {
    display: flex;
    flex-wrap: wrap; }
  .p-org {
    width: 8.75rem; }
  .h-adr {
    width: 10.625rem; }
  .p-org, .h-adr, .p-tel, .u-email {
    margin-bottom: 0; }
  .h-adr, .p-tel, .u-email {
    margin-right: 2.5rem; } }

.intro-block__title {
  font-size: 2rem;
  font-weight: 400;
  letter-spacing: 0.09094em;
  text-align: center;
  margin: 0 0 1.625rem; }

.intro-block__text {
  margin: 0;
  font-size: 1.25rem;
  letter-spacing: 0.094em;
  text-align: center; }

.nav-primary {
  background-color: #fff;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding-left: 1.5625rem;
  z-index: 99;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transform: translateX(100%);
  transition-property: transform;
  transition-duration: .3s;
  transition-timing-function: ease-in-out;
  transition-delay: 0s; }
  .nav-primary__item {
    font-size: 2.5rem;
    letter-spacing: -0.021em;
    line-height: 1;
    color: #D2D2D7; }
  .nav-primary__item a {
    position: relative;
    display: inline-block;
    padding-bottom: 2px; }
  .show-menu .nav-primary {
    transform: translateX(0); }
    .show-menu .nav-primary__item a {
      transition: color .3s ease-in-out; }
      .show-menu .nav-primary__item a::after {
        height: 2px;
        background-color: #0433FF; }
      .show-menu .nav-primary__item a:hover {
        color: #0433FF; }
  .desktop .nav-primary {
    transition-duration: 0s; }

@media screen and (min-width: 768px) {
  .nav-primary {
    color: #0433FF;
    background-color: transparent;
    position: relative;
    display: block;
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
    padding-left: 0;
    z-index: auto;
    transform: translateX(0);
    transition-duration: 0s; }
    .nav-primary__mobile-ui {
      display: none; }
    .nav-primary__list {
      display: flex;
      align-items: center; }
    .nav-primary__item {
      font-size: 0.875rem; }
    .nav-primary__item + .nav-primary__item {
      margin-left: 2.5rem; }
    .nav-primary__item:last-child {
      border: 1px solid;
      display: inline-flex;
      height: 2.5rem;
      align-items: center;
      justify-content: center;
      border-radius: 0.125rem;
      padding: 0.57143em 1em; }
      .nav-primary__item:last-child a {
        padding-bottom: 0; }
      .nav-primary__item:last-child a::after {
        display: none; } }

.nav-secondary {
  margin-bottom: 1.875rem;
  font-weight: 400; }
  .nav-secondary__list {
    display: flex;
    align-items: center; }
  .nav-secondary__item + .nav-secondary__item {
    margin-left: 2.5rem; }

@media screen and (min-width: 768px) {
  .nav-secondary {
    margin-bottom: 0; }
    .nav-secondary__item:first-child {
      width: 8.75rem; }
    .nav-secondary__item + .nav-secondary__item {
      margin-left: 0; } }

.nav-social {
  margin-bottom: 1.875rem; }
  .nav-social__list {
    display: flex;
    align-items: center; }
  .nav-social__item {
    width: 1.125rem;
    height: 1.125rem; }
  .nav-social__item + .nav-social__item {
    margin-left: 2.1875rem; }
  .nav-social a::after {
    display: none; }

@media screen and (min-width: 768px) {
  .nav-social {
    margin-bottom: 0; } }

.progress-widget p {
  font-size: 1rem;
  line-height: 1.25;
  margin: 1.25rem 0 0; }

.service-block {
  text-align: center;
  margin-bottom: 1.25rem; }
  .service-block__visual svg {
    width: 1.875rem;
    height: auto; }
    .service-block__visual svg path {
      fill: #D2D2D7; }
  .service-block__title {
    color: #0433FF;
    font-weight: 400;
    letter-spacing: 0.25em;
    line-height: 1.18182;
    text-transform: uppercase;
    margin: 1.25rem 0; }
  .service-block__description {
    margin: 0;
    font-size: 1rem;
    letter-spacing: 0.0625em;
    line-height: 1.5; }

.brands {
  padding-top: 3.75rem; }
  .brands__title {
    text-transform: uppercase;
    text-align: center;
    margin: 0 0 2.5rem; }
  .brands__logos {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
    overflow-y: hidden;
    overflow-x: scroll; }
    .brands__logos span {
      margin: 0 1.25rem; }

@media screen and (min-width: 768px) {
  .brands {
    padding-top: 4.6875rem;
    margin-bottom: -2.5rem; }
    .brands__title {
      margin: 0 0 3.75rem; }
    .brands__logos {
      margin: 0 -1.875rem;
      flex-wrap: wrap;
      justify-content: center;
      overflow: visible;
      overflow-y: visible;
      overflow-x: visible; }
      .brands__logos span {
        margin: 0 1.875rem 2.5rem; } }

.case-teaser {
  color: #0433FF;
  background-color: #fff;
  height: 25.625rem;
  position: relative;
  overflow: hidden;
  padding-top: 2.5rem; }
  .case-teaser .award {
    position: absolute;
    top: 0.9375rem;
    right: 0;
    width: 3.25rem; }
    .case-teaser .award img {
      width: 100%;
      height: auto;
      display: block; }
  .case-teaser .case-meta {
    font-size: 0.75rem; }
    .case-teaser .case-meta__date {
      margin-bottom: 0.9375rem;
      display: inline-block; }
  .case-teaser__description {
    display: none; }
  .case-teaser .btn-text {
    margin-top: 3.472vw; }
  .case-teaser__visual {
    position: absolute; }
    .case-teaser__visual img {
      width: 100%;
      height: auto; }
  .case-teaser__title {
    margin: .3em 0;
    font-weight: 400;
    font-size: 2.5rem;
    letter-spacing: -0.021em; }

@media screen and (min-width: 768px) {
  .case-teaser {
    height: 62.5vw;
    padding-top: 0; }
    .case-teaser .case-meta {
      display: flex;
      position: relative; }
      .case-teaser .case-meta__date {
        font-weight: 300;
        position: absolute;
        left: -4.861vw;
        margin-bottom: 0; }
    .case-teaser__content {
      padding: 0 0 0 4.861vw;
      width: 100%;
      max-width: 100%;
      width: 100%; }
    .case-teaser__inner {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      position: relative; }
    .case-teaser__description {
      display: block;
      font-size: 1rem;
      max-width: 27.291vw;
      width: 100%;
      color: #323241; } }

@media screen and (min-width: 1100px) {
  .case-teaser .award {
    top: 4.375rem;
    width: 7.875rem; }
  .case-teaser__description {
    font-size: 1.25rem; } }

@media screen and (min-width: 1440px) {
  .case-teaser {
    height: 56.25rem; }
    .case-teaser__content {
      padding: 0 0 0 4.375rem; }
    .case-teaser .case-meta__date {
      left: -4.375rem; }
    .case-teaser__description {
      max-width: 24.5625rem; }
    .case-teaser .btn-text {
      margin-top: 3.125rem; } }

.case-teaser--bouncewear {
  background-color: #0433FF;
  color: #fff; }
  .case-teaser--bouncewear .case-teaser__description {
    color: inherit; }
  .case-teaser--bouncewear .case-teaser__visual {
    top: 8.75rem;
    left: 2.3125rem;
    width: 30.25rem;
    height: 14.9375rem; }

@media screen and (min-width: 768px) {
  .case-teaser--bouncewear .case-teaser__title {
    font-size: 11.805vw;
    letter-spacing: -0.04771em;
    font-weight: 400; }
  .case-teaser--bouncewear .case-teaser__visual {
    top: 9.72vw;
    left: 37.5vw;
    width: 78.13vw;
    height: 39.38vw; } }

@media screen and (min-width: 1440px) {
  .case-teaser--bouncewear .case-teaser__title {
    font-size: 10rem;
    letter-spacing: -0.02712em; }
  .case-teaser--bouncewear .case-teaser__visual {
    top: 8.75rem;
    left: 33.75rem;
    width: 70.3125rem;
    height: 35.4375rem; } }

.case-teaser--martens {
  background-color: #001446;
  color: #fff; }
  .case-teaser--martens .case-teaser__title {
    max-width: 18.75rem; }
  .case-teaser--martens .case-teaser__description {
    color: inherit; }
  .case-teaser--martens .case-teaser__visual {
    width: 10.25rem;
    height: 15.3125rem;
    left: 11.25rem;
    top: 8.4375rem; }

@media screen and (min-width: 768px) {
  .case-teaser--martens .case-teaser__title {
    font-size: 6.944vw;
    display: flex;
    font-weight: 400;
    max-width: 100%; }
    .case-teaser--martens .case-teaser__title span:last-child {
      position: relative;
      left: 19.79vw; }
  .case-teaser--martens .case-teaser__visual {
    width: 25.624vw;
    height: 38.680vw;
    left: 31.46vw;
    top: 12.85vw; }
  .case-teaser--martens .case-teaser__description {
    max-width: 25.14vw; } }

@media screen and (min-width: 1440px) {
  .case-teaser--martens .case-teaser__title {
    font-size: 6.25rem; }
    .case-teaser--martens .case-teaser__title span:last-child {
      left: 17.8125rem; }
  .case-teaser--martens .case-teaser__visual {
    top: 11.5625rem;
    left: 28.3125rem;
    width: 23.0625rem;
    height: 34.8125rem; }
  .case-teaser--martens .case-teaser__description {
    max-width: 22.625rem; } }

.case-teaser--tutushop {
  background-color: #fff;
  color: #0433FF; }
  .case-teaser--tutushop .case-teaser__visual {
    width: 9.625rem;
    height: 16rem;
    left: 8.875rem;
    top: 9.8125rem; }

@media screen and (min-width: 768px) {
  .case-teaser--tutushop .case-teaser__title {
    font-size: 11.805vw;
    letter-spacing: -0.04771em;
    font-weight: 400; }
    .case-teaser--tutushop .case-teaser__title span:last-child {
      position: relative;
      left: 9.72vw; }
  .case-teaser--tutushop .case-teaser__visual {
    width: 20.763vw;
    height: 52.013vw;
    top: 4.31vw;
    left: 44.1vw; } }

@media screen and (min-width: 1440px) {
  .case-teaser--tutushop .case-teaser__title {
    font-size: 10.625rem; }
    .case-teaser--tutushop .case-teaser__title span:last-child {
      left: 8.75rem; }
  .case-teaser--tutushop .case-teaser__visual {
    top: 3.875rem;
    left: 39.6875rem;
    width: 18.6875rem;
    height: 46.8125rem; } }

.site-footer {
  padding: 3.75rem 0 2.5rem;
  border-top: 1px solid #F0F0F5;
  font-size: 0.875rem;
  font-weight: 300;
  letter-spacing: 0.10714em;
  line-height: 1.57143; }
  .site-footer a {
    color: inherit;
    text-decoration: none; }
  .site-footer__legal {
    color: rgba(50, 50, 65, 0.5);
    font-size: 0.75rem;
    letter-spacing: 0.1075em;
    line-height: 1.83333;
    margin-top: 1.875rem; }
    .site-footer__legal span + span::before {
      content: '/';
      display: inline-block;
      padding: 0 .8em; }

@media screen and (min-width: 768px) {
  .site-footer {
    padding: 6.25rem 0 5rem; }
    .site-footer__inner > div + div {
      margin-top: 3.75rem; }
    .site-footer__nav {
      display: flex;
      justify-content: space-between; }
    .site-footer__legal span + span {
      margin-left: 1.875rem; }
      .site-footer__legal span + span::before {
        display: none; } }

.get-in-touch {
  padding: 4.375rem 0 2.5rem; }
  .get-in-touch__inner {
    display: flex;
    flex-direction: column;
    align-items: center; }
  .get-in-touch .subscribe-form {
    max-width: 34.1875rem;
    width: 100%;
    margin-top: 3.125rem; }

@media screen and (min-width: 768px) {
  .get-in-touch {
    padding: 6.25rem 0; } }

.site-header__inner {
  height: 5.375rem;
  display: flex;
  justify-content: space-between;
  align-items: center; }

.site-header--blue {
  background-color: #0433FF; }
  .site-header--blue .nav-primary {
    color: #fff; }
  .site-header--blue .logo svg path {
    fill: #fff; }

.show-menu .site-header--blue .logo {
  z-index: 100; }

.show-menu .site-header--blue .logo svg path {
  fill: #0433FF; }

@media screen and (min-width: 768px) {
  .site-header__inner {
    height: 7.5rem; } }

.hero {
  background-color: #0433FF;
  color: #fff;
  height: calc(100vh - 86px);
  position: relative;
  overflow: hidden; }
  .hero__inner {
    height: 100%;
    display: flex;
    align-items: center; }
  .hero__title {
    margin: 0;
    font-size: 12.5vw;
    letter-spacing: -0.021em;
    line-height: 1;
    font-weight: 400;
    position: absolute; }
    .hero__title__part {
      display: block;
      position: relative;
      z-index: 1; }
  .hero__visual {
    width: 60vw;
    height: 140vw;
    background-image: url("../img/hero/hand.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: top right;
    position: absolute;
    right: 0.625rem;
    z-index: 0; }
  .hero__social {
    font-size: 0.75rem;
    letter-spacing: -0.02083em;
    color: #fff;
    position: absolute;
    transform: rotate(90deg);
    transform-origin: top right;
    bottom: 3.125rem;
    right: 1.5625rem;
    z-index: 100; }
    .hero__social a {
      display: inline-block;
      color: #fff;
      text-decoration: none;
      transition: color .3s ease-in-out; }
    .hero__social a + a {
      margin-left: 2em; }
  .hero__text {
    display: none; }
  .hero .btn-down {
    position: absolute;
    left: 1.5625rem;
    bottom: 3.125rem;
    z-index: 3; }

.show-menu .hero__social a {
  color: #0433FF; }

@media screen and (min-width: 768px) {
  .hero {
    height: 54.166vw; }
    .hero__inner {
      display: block; }
    .hero__title {
      font-size: 6.666vw;
      letter-spacing: -0.02083em;
      top: 0;
      left: 0;
      right: 0; }
      .hero__title__part {
        position: absolute; }
        .hero__title__part:nth-child(1), .hero__title__part:nth-child(2) {
          top: 13.888vw; }
        .hero__title__part:nth-child(1), .hero__title__part:nth-child(3) {
          right: 50%; }
        .hero__title__part:nth-child(2) {
          left: calc(50% + 5.555vw); }
        .hero__title__part:nth-child(4) {
          left: calc(50% + 8.68vw); }
        .hero__title__part:nth-child(3), .hero__title__part:nth-child(4) {
          top: 21.527vw; }
        .hero__title__part:nth-child(1) {
          z-index: 2; }
        .hero__title__part:nth-child(2), .hero__title__part:nth-child(4) {
          z-index: 0; }
        .hero__title__part:nth-child(3) {
          z-index: 2; }
    .hero__visual-part, .hero__visual {
      width: 27.777vw;
      height: 65.822vw;
      top: 0;
      right: auto;
      left: calc(50% - 2.777vw);
      transform: translateX(-50%);
      z-index: 1; }
    .hero__visual-part {
      position: absolute;
      z-index: 3;
      background-image: url("../img/hero/hand-finger-layer.png");
      background-size: contain;
      background-repeat: no-repeat; }
    .hero__text {
      display: block;
      position: absolute;
      top: auto;
      bottom: 18.055vw;
      left: 0;
      right: 0;
      text-align: center;
      z-index: 3;
      padding: 0 1.875rem; }
    .hero .btn-down {
      position: absolute;
      left: 50%;
      margin-left: -1.25rem;
      z-index: 3;
      bottom: 4.166vw; } }

@media screen and (min-width: 1440px) {
  .hero {
    height: 48.75rem; }
    .hero__title {
      font-size: 6rem;
      letter-spacing: -0.02083em; }
      .hero__title__part:nth-child(1), .hero__title__part:nth-child(2) {
        top: 12.5rem; }
      .hero__title__part:nth-child(2) {
        left: calc(50% + 5rem); }
      .hero__title__part:nth-child(4) {
        left: calc(50% + 7.8125rem); }
      .hero__title__part:nth-child(3), .hero__title__part:nth-child(4) {
        top: 19.375rem; }
    .hero__visual-part, .hero__visual {
      left: calc(50% - 2.5rem);
      width: 25rem;
      height: 59.24312rem; }
    .hero__text {
      bottom: 16.25rem; }
    .hero .btn-down {
      bottom: 3.75rem; } }

.service-group {
  padding: 2.5rem 0; }
  .service-group__inner {
    display: flex;
    flex-direction: column;
    align-items: center; }
  .service-group .service-block {
    max-width: 18.75rem;
    padding: 0 1.25rem;
    width: 100%; }
  .service-group .service-block + .service-block {
    margin-top: 2.5rem; }

@media screen and (min-width: 768px) {
  .service-group {
    padding: 7.1875rem 0; }
    .service-group__inner {
      flex-direction: row;
      justify-content: space-between;
      padding: 0 3.125rem; }
    .service-group .service-block + .service-block {
      margin-top: 0; } }

.constrict {
  max-width: 78.125rem;
  margin: auto;
  padding: 0 1.5625rem; }
  @media screen and (min-width: 768px) {
    .constrict {
      padding: 0 1.875rem; } }

.stylesheet__inner {
  padding: 6.25rem 0 0; }

.stylesheet__section {
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  padding-bottom: 3.125rem;
  padding-left: 1.25rem; }
  .stylesheet__section:last-child {
    border: none; }

.stylesheet .has-bg {
  padding: 0.625rem;
  background-color: rgba(0, 0, 0, 0.2); }

.stylesheet__title {
  margin: 1em 0;
  color: rgba(0, 0, 0, 0.5);
  font-weight: 300; }
  h1.stylesheet__title {
    color: rgba(0, 0, 0, 0.15);
    font-size: 2.5rem;
    text-transform: uppercase;
    margin-top: 0; }
  h2.stylesheet__title {
    text-transform: uppercase; }
    h2.stylesheet__title::before {
      content: '•';
      display: inline-block;
      width: 1.25rem; }

.stylesheet + .stylesheet .stylesheet__inner {
  border-top: 3px double rgba(0, 0, 0, 0.15); }

.stylesheet__section + .stylesheet__title {
  margin-top: 3.125rem; }

.stylesheet__tags,
.stylesheet__logos,
.stylesheet__buttons,
.stylesheet__icons {
  display: flex;
  flex-wrap: wrap;
  align-items: center; }
  .stylesheet__tags + .stylesheet__tags,
  .stylesheet__logos + .stylesheet__tags,
  .stylesheet__buttons + .stylesheet__tags,
  .stylesheet__icons + .stylesheet__tags, .stylesheet__tags +
  .stylesheet__logos,
  .stylesheet__logos +
  .stylesheet__logos,
  .stylesheet__buttons +
  .stylesheet__logos,
  .stylesheet__icons +
  .stylesheet__logos, .stylesheet__tags +
  .stylesheet__buttons,
  .stylesheet__logos +
  .stylesheet__buttons,
  .stylesheet__buttons +
  .stylesheet__buttons,
  .stylesheet__icons +
  .stylesheet__buttons, .stylesheet__tags +
  .stylesheet__icons,
  .stylesheet__logos +
  .stylesheet__icons,
  .stylesheet__buttons +
  .stylesheet__icons,
  .stylesheet__icons +
  .stylesheet__icons {
    margin-top: 1.25rem; }
  .stylesheet__tags * + *,
  .stylesheet__logos * + *,
  .stylesheet__buttons * + *,
  .stylesheet__icons * + * {
    margin-left: 1.25rem; }

.stylesheet .icn {
  display: inline-block; }
  .stylesheet .icn svg {
    display: block; }

.stylesheet .color-block {
  width: 3.125rem;
  height: 3.125rem; }

.stylesheet .color-border {
  width: 3.125rem;
  height: 3.125rem;
  border-width: 2px;
  border-style: solid; }

.stylesheet .color-icon {
  width: 1.875rem;
  height: 1.875rem;
  display: flex;
  justify-content: center;
  align-items: center; }
