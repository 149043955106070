.subscribe-form {

  .text-input {
    text-align: center;
  }

  .btn {
    max-width: 100%;
    width: 100%;
    margin-top: rem(20);
  }
}


@include above($tablet) {
  .subscribe-form {
    display: flex;
    justify-content: space-between;

    .text-input {
      width: calc(100% - #{rem(120)});
      text-align: left;
    }

    .btn {
      margin: 0;
      width: rem(105)
    }
  }

}
