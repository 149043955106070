.mobile-nav-trigger {
  position: absolute;
  z-index: 100;
  right: rem(20);
  font-size: rem(40);
  color: $white;
  height: rem(40);
  width: rem(40);
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;

  &::before,
  &::after {
    content: '';
    display: block;
    width: rem(20);
    height: rem(1.5);
    position: absolute;
    background-color: $white;
    transform: rotate(0);
    transition-property: background-color, margin-top, transform;
    transition-duration: .3s;
    transition-timing-function: ease-in-out;
    transition-delay: 0s, .3s, 0s;
  }

  &::before {
    margin-top: rem(-3);
  }

  &::after {
    margin-top: rem(3);
  }


  .show-menu & {

    &::before,
    &::after {
      background-color: $primary-color;
      transition-delay: 0s, 0s, .3s;
    }

    &::before {
      margin-top: 0;
      transform: rotate(-45deg);
    }

    &::after {
      margin-top: 0;
      transform: rotate(45deg);
    }
  }
}


@include above($tablet) {
  .mobile-nav-trigger {
    display: none;
  }
}
