a {
  position: relative;
  display: inline-block;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    display: block;
    width: 100%;
    height: 1px;
    background-color: currentColor;
    transition: transform .3s ease-in-out;

    @include link-hover(leave);
  }

  &:hover {
    &::after {
      @include link-hover(enter);
    }
  }
}
