.site-footer {
  padding: rem(60) 0 rem(40);
  border-top: 1px solid #F0F0F5;
  font-size: rem(14);
  font-weight: 300;
  letter-spacing: ls(1.5, 14);
  line-height: lh(22, 14);

  a {
    color: inherit;
    text-decoration: none;
  }

  &__legal {
    color: rgba($text-color, .5);
    font-size: rem(12);
    letter-spacing: ls(1.29, 12);
    line-height: lh(22, 12);
    margin-top: rem(30);

    span + span {
      &::before {
        content: '/';
        display: inline-block;
        padding: 0 .8em;
      }
    }
  }
}




@include above($tablet) {
  .site-footer {
    padding: rem(100) 0 rem(80);

    &__inner > div + div {
      margin-top: rem(60);
    }

    &__nav {
      display: flex;
      justify-content: space-between;

    }

    &__legal {
      span + span {
        margin-left: rem(30);
        &::before {
          display: none;
        }
      }
    }
  }
}
