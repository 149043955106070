.brands {
  padding-top: rem(60);
  // margin-bottom: rem(40);

  &__title {
    text-transform: uppercase;
    text-align: center;
    margin: 0 0 rem(40);
  }

  &__logos {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
    overflow-y: hidden;
    overflow-x: scroll;

    span {
      margin: 0 rem(20);
    }

    span + span {
      // margin-left: rem(40);
    }
  }
}


@include above($tablet) {

  .brands {
    padding-top: rem(75);
    margin-bottom: rem(-40);

    &__title {
      margin: 0 0 rem(60);
    }

    &__logos {
      margin: 0 rem(-30);
      flex-wrap: wrap;
      justify-content: center;
      overflow: visible;
      overflow-y: visible;
      overflow-x: visible;

      span {
        margin: 0 rem(30) rem(40);
      }
    }
  }
}
