.tag {
  font-size: rem(12);
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: ls(2, 12);

  @include above($tablet) {
    font-size: rem(14);
    letter-spacing: ls(1.31, 14);
  }
}
