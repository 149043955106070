.case-teaser {
  @include c-primary('color');
  @include c-negative('background-color');
  height: rem(410);
  position: relative;
  overflow: hidden;
  padding-top: rem(40);

  .award {
    position: absolute;
    top: rem(15);
    right: 0;
    width: rem(52);

    img {
      width: 100%;
      height: auto;
      display: block;
    }
  }


  .case-meta {
    font-size: rem(12);

    &__date {
      margin-bottom: rem(15);
      display: inline-block;
    }
  }

  &__description {
    display: none;
  }

  .btn-text {
    margin-top: 3.472vw;
  }

  &__visual {
    position: absolute;

    img {
      width: 100%;
      height: auto;
    }

  }

  &__title {
    margin: .3em 0;
    font-weight: 400;
    // font-size: 12.5vw;
    font-size: rem(40);
    letter-spacing: ls(-0.84, 40);
  }
}


@include above($tablet) {
  .case-teaser {
    height: 62.5vw;
    padding-top: 0;

    .case-meta {
      display: flex;
      position: relative;

      &__date {
        font-weight: 300;
        position: absolute;
        left: -4.861vw;
        margin-bottom: 0;
      }
    }

    &__content {
      padding: 0 0 0 4.861vw;
      width: 100%;
      max-width: 100%;
      width: 100%;
    }

    &__inner {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      position: relative;
    }

    &__description {
      display: block;
      font-size: rem(16);
      max-width: 27.291vw;
      width: 100%;
      color: #323241;
    }
  }
}

@include above($desktop) {
  .case-teaser {
    .award {
      top: rem(70);
      width: rem(126);
    }

    &__description {
      font-size: rem(20);
    }
  }
}

@include above($desktop-hd) {
  .case-teaser {
    height: rem(900);

    &__content {
      padding: 0 0 0 rem(70);
    }

    .case-meta__date {
      left: rem(-70);
    }

    &__description {
      max-width: rem(393);
    }

    .btn-text {
      margin-top: rem(50);
    }
  }

}
