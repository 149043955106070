
.intro-block {
  &__title {
    font-size: rem(32);
    font-weight: 400;
    letter-spacing: ls(2.91,32);
    text-align: center;
    margin: 0 0 rem(26);
  }

  &__text {
    margin: 0;
    font-size: rem(20);
    letter-spacing: ls(1.88, 20);
    text-align: center;
  }
}
