.service-group {
  padding: rem(40) 0;

  &__inner {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .service-block {
    max-width: rem(300);
    padding: 0 rem(20);
    width: 100%;
  }

  .service-block + .service-block {
    margin-top: rem(40);
  }
}


@include above($tablet) {
  .service-group {
    padding: rem(115) 0;

    &__inner {
      flex-direction: row;
      justify-content: space-between;
      padding: 0 rem(50);
    }

    .service-block + .service-block {
      margin-top: 0;
    }
  }
}
