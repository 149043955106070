button {
  padding: 0;
  appearance: none;
  border: none;
  background: transparent;
  cursor: pointer;
}

.btn {
  color: inherit;
  display: inline-flex;
  height: rem(50);
  align-items: center;
  justify-content: center;
  border-radius: rem(2);
  font-size: rem(14);
  padding: em(8, 14) em(14, 14);
  text-transform: uppercase;
  font-weight: 700;

  @include when-is(a) {
    text-decoration: none;

    &::after {
      display: none;
    }
  }

  @include above($tablet) {
    height: rem(40);
  }
}

.btn--ghost {
  @include c-primary('color');

  border: 1px solid;
  background-color: transparent;

  &.btn--white {
    @include c-negative('color');
  }
}


.btn--primary {
  @include c-primary('border-color', 'background-color');
  @include c-negative('color');

  border-width: 1px;
  border-style: solid;

  &.btn--white {
    @include c-negative('border-color', 'background-color');
    @include c-primary('color');
  }
}

.btn--large {
  height: rem(50);
  font-size: rem(16);
  max-width: rem(276);
  width: 100%;
}

.btn-down {
  @include circle(rem(40));
  @include c-primary('background-color');
  @include c-negative('color');

  border: 2px solid;
  display: inline-flex;
  justify-content: center;
  align-items: center;

  svg {
    margin: rem(3) 0 0 0;
    display: block;
    width: rem(26);
    height: auto;

    path, polygon {
      fill: $white;
    }
  }
}


.btn-text {
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  font-size: rem(14);
  text-transform: uppercase;
  font-weight: 700;
  color: inherit;
  position: relative;

  &::after {
    width: auto;
    left: 0;
    right: 1.5em;
  }

  .icn {
    margin: 0 0 0 .2em;
  }

  svg {
    display: block;
    path, polygon {
      fill: currentColor;
    }
  }

  &--white {
    color: $white;
  }
}
