.detail-list {
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: rem(14);
  font-weight: 300;
  letter-spacing: ls(1.5,14);
  line-height: 1;
  color: #5F5F6E;

  li {
    display: flex;
    margin-bottom: rem(22);

    span:first-child {
      width: em(140,14);
    }
    span {
      border-bottom: 1px solid
    }

    a {
      color: inherit;
      text-decoration: none;
    }
  }
}
