blockquote {
  @include c-primary('color');

  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: rem(100) 0;

  &::before {
    content: '“';
    font-size: rem(100);
    font-weight: 300;
    font-style: italic;
    line-height: lh(37,100);
    margin-bottom: rem(20);
  }

  p, cite {
    font-size: rem(18);
    line-height: lh(37,18);
    letter-spacing: ls(1.5, 18);
    max-width: rem(685);
  }
  p {
    font-weight: 700;
    text-transform: uppercase;
    margin: 0;
  }

  cite {
    font-weight: 300;
    font-style: normal;
    margin-top: rem(20);
    text-decoration: underline;
  }
}
