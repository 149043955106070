.nav-social {
  margin-bottom: rem(30);

  &__list {
    display: flex;
    align-items: center;
  }

  &__item {
    width: rem(18);
    height: rem(18);
  }

  &__item + &__item {
    margin-left: rem(35);
  }

  a::after {
    display: none;
  }
}



@include above($tablet) {
  .nav-social {
    margin-bottom: 0;
  }
}
