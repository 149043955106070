.text-input {
  // height: rem(40);
  font-size: rem(14);
  letter-spacing: ls(1.75, 14);
  width: 100%;
  border: none;
  border-bottom: 1px solid #D2D2D7;
  padding-top: rem(15);
  padding-bottom: rem(8);

  @include placeholder() {
    color: rgba(#5F5F6E, .5);
  }
}
