.stylesheet {
  &__inner { padding: rem(100) 0 0; }
  &__section {
    border-bottom: 1px solid rgba($black, .15);
    padding-bottom: rem(50);
    padding-left: rem(20);

    &:last-child {
      border: none;
    }
  }

  .has-bg {
    padding: rem(10);
    background-color: rgba($black, .2);
  }
}


.stylesheet__title {
  margin: 1em 0;
  color: rgba($black, .5);
  font-weight: 300;

  @include when-is(h1) {
    color: rgba($black, .15);
    font-size: rem(40);
    text-transform: uppercase;
    margin-top: 0;
  }

  @include when-is(h2) {
    text-transform: uppercase;
    &::before {
      content: '•';
      display: inline-block;
      width: rem(20);
    }
  }
}

.stylesheet + .stylesheet .stylesheet__inner {
  border-top: 3px double rgba($black, .15);
}

.stylesheet__section + .stylesheet__title {
  margin-top: rem(50);
}

.stylesheet__tags,
.stylesheet__logos,
.stylesheet__buttons,
.stylesheet__icons {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  & + & {
    margin-top: rem(20)
  }

  * + * {
    margin-left: rem(20);
  }
}

.stylesheet .icn {
  display: inline-block;

  svg {
    display: block;
  }
}


.stylesheet .color-block {
  @include size(rem(50));
}

.stylesheet .color-border {
  @include size(rem(50));
  border-width: 2px;
  border-style: solid;
}

.stylesheet .color-icon {
  @include size(rem(30));
  display: flex;
  justify-content: center;
  align-items: center;
}
