.nav-primary {
  background-color: $white;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding-left: rem(25);
  z-index: 99;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transform: translateX(100%);
  transition-property: transform;
  transition-duration: .3s;
  transition-timing-function: ease-in-out;
  transition-delay: 0s;


  &__item {
    font-size: rem(40);
    letter-spacing: ls(-0.84, 40);
    line-height: 1;
    color: #D2D2D7;
  }

  &__item a {
    position: relative;
    display: inline-block;
    padding-bottom: 2px;
  }

  .show-menu & {
    transform: translateX(0);

    &__item a {
      transition: color .3s ease-in-out;

      &::after {
        height: 2px;
        @include c-primary('background-color');
      }

      &:hover {
        @include c-primary('color');
      }
    }
  }

  .desktop & {
    transition-duration: 0s;
  }
}


@include above($tablet) {
  .nav-primary {
    @include c-primary('color');

    // reset styles
    background-color: transparent;
    position: relative;
    display: block;
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
    padding-left: 0;
    z-index: auto;
    transform: translateX(0);
    transition-duration: 0s;

    &__mobile-ui {
      display: none;
    }

    &__list {
      display: flex;
      align-items: center;
    }

    &__item {
      font-size: rem(14);
    }

    &__item + &__item {
      margin-left: rem(40);
    }

    &__item:last-child {
      border: 1px solid;
      display: inline-flex;
      height: rem(40);
      align-items: center;
      justify-content: center;
      border-radius: rem(2);
      padding: em(8, 14) em(14, 14);

      a { padding-bottom: 0; }
      a::after { display: none; }
    }
  }
}
