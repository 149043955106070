
.case-teaser--bouncewear {
  @include c-primary('background-color');
  @include c-negative('color');

  .case-teaser__description {
    color: inherit;
  }

  .case-teaser__visual {
    top: rem(140);
    left: rem(37);
    width: rem(484);
    height: rem(239);
  }
}

@include above($tablet) {
  .case-teaser--bouncewear {
    .case-teaser__title {
      font-size: 11.805vw;
      letter-spacing: ls(-8.11, 170);
      font-weight: 400;
    }

    .case-teaser__visual {
      top: 9.72vw;
      left: 37.5vw;
      width: 78.13vw;
      height: 39.38vw;
    }
  }
}

@include above($desktop-hd) {
.case-teaser--bouncewear {
    .case-teaser__title {
      font-size: rem(160);
      letter-spacing: ls(-4.34, 160);
    }

    .case-teaser__visual {
      top: rem(140);
      left: rem(540);
      width: rem(1125);
      height: rem(567);
    }
  }
}
